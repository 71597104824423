import React, { useContext } from 'react';
import { landingStyles } from '../styles/PageStyles';
import { RouteContext } from '../Functions/Router';
import { Button } from '@fluentui/react-components';
import Header from '../components/Header';

export default function Landing() {
  const route = useContext(RouteContext);
  const styles = landingStyles();
  return(
    <>
      <Header />
      <div className={styles.banner}>
        <h1 className={styles.greeting}>Welcome to your CyberSecurity Assistant!</h1>
        <p>Please login to start using your Mail Guard account. If you do not have one, please use the button below to get started.</p>
        <div className={styles.buttonRow}>
          <Button size="small" appearance='primary' onClick={() => route.setLocation('login')}>LOGIN</Button>
          <Button size="small" appearance='primary' onClick={() => window.open('https://mailguard.greyforward.com/')}>SIGN UP</Button>
        </div>
      </div>
    </>
  )
}