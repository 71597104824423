import React, { useState } from 'react'

export default function CaptureText(){
  const [ position, setPosition ] = useState<number>(0);
  const textList = ["Capturing Email...", "Calling Postmaster...", "Submitting Email...", "Waiting for results...", "Loading Response..."];

  setInterval(() => {
    if(position != 4){
      setPosition(position + 1)
    }
  }, 1500);

  return(
    <>
      <div style={{ marginTop: '15px'}}>
        {textList[position]}
      </div>
    </>
  )
};