import React, { useContext, useEffect, useState } from 'react';

import { Button, makeStyles } from '@fluentui/react-components';
import { RouteContext } from '../Functions/Router';
import ChildPassword from '../components/ChildPassword';
import Header from '../components/Header';

const welcomeStyles = makeStyles({
  flexCenter: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: '20px'
  },
  welcomeText: {
    marginLeft: '10px',
    marginRight: '10px'
  }
})

export default function Welcome(){
  const styles = welcomeStyles();
  const route = useContext(RouteContext);
  const [showChildPw, setShowChildPw] = useState<boolean>(false);

  useEffect(() => {
    if(localStorage.getItem('newChild')){
      setShowChildPw(true);
    } else {
      localStorage.removeItem('splash')
    }
  }, []);

  if(showChildPw){
    return(
      <ChildPassword />
    )
  }

  return(
    <>
      <Header />
      <div className={styles.flexCenter}>
        <h1>Login Successful!</h1>
      </div>
      <div className={styles.welcomeText}>
        <p>Hello and welcome to the MailGuard analysis platform.</p>
        <p>Using this tool you will be able to get a risk assessment for any email you may enocunter with malicious intention.</p>
        <p>For support, tutorials, and more please head over to the <a href="https://google.com">Knowledge Base</a> for more information</p>
      </div>
      <div className={styles.flexCenter}>
        <Button appearance='primary' size='large' onClick={() => route.setLocation('analyze')}>Begin!</Button>
      </div>
    </>
  )
};