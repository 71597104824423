import * as React from "react";
import { createRoot } from "react-dom/client";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";
import './styles/styles.css'

import { AuthWrapper } from "./components/AuthProvider";

/* global document, Office, module, require */


const rootElement: HTMLElement | null = document.getElementById("container");
const root = rootElement ? createRoot(rootElement) : undefined;

/* Render application after Office initializes */
Office.onReady(() => {
  root?.render(
    <FluentProvider theme={webLightTheme}>
      <AuthWrapper />
    </FluentProvider>,
  );
});

// if ((module as any).hot) {
//   (module as any).hot.accept("./App", () => {
//     const NextApp = require("./App").default;
//     root?.render(NextApp);
//   });
// }
