import React from 'react';
import { Image, makeStyles } from '@fluentui/react-components';
//@ts-expect-error Need to figure out the faulty imports
import MainLogo from '../assets/GFLogo.png'

const footerStyles = makeStyles({
  footer: {
    position: 'relative',
    width: '100%',
    bottom: '0px',
    display: 'Flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
  },
  divLink: {
    cursor: 'pointer'
  },
  title: {
    lineHeight: '1em',
    padding: '0px',
    margin: '5px',
    color: '#868789'
  }
})

export default function Footer(){
  const styles = footerStyles();
  return(
    <>
      <div className={styles.footer}>
        <div className={styles.divLink} onClick={() => window.open('https://greyforward.com/')}>
          <h5 className={styles.title}>Powered By:</h5>
          <Image width={'150px'} height={'auto'} src={MainLogo} />
        </div>
      </div>
    </>
  )
}