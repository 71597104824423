import React, { useState } from 'react';
import { feedbackStyles } from '../styles/ComponentStyles';
import { Textarea, Field, Button, MessageBar, MessageBarBody, Label } from '@fluentui/react-components';
import { ThumbLikeRegular, ThumbDislikeRegular } from '@fluentui/react-icons';

interface FeedbackProps {
  ID: string;
  feedback: () => void;
}

export default function Feedback(props: FeedbackProps){
  const styles = feedbackStyles();
  const [ rating, setRating ] = useState<'positive' | 'negative' | undefined>(undefined);
  const [ review, setReview ] = useState<string>('');
  const [ message, setMessage ] = useState<{intent: 'success' | 'error', result: string}>();
  const [ disabled, setDisabled ] = useState<boolean>(false);

  const submitFeedback: () => Promise<void> = async () => {
    if(rating == undefined){
      setMessage({ intent: 'error', result: 'Please select feedback from above'});
      return
    } else {
      setDisabled(true);
    };

    try {
      const response = await fetch(`${process.env.APISERVER}/scan/feedback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ ID: props.ID, review, rating})
      });

      if(response.ok){
        setMessage({ intent: 'success', result: 'Feedback recieved successfully'});
        props.feedback();
        return;
      } else {
        const data = await response.json();
        setMessage({ intent: 'error', result: data.message});
        setDisabled(false);
      }
    } catch(e){
      console.log(e)
      setMessage({ intent: 'error', result: 'Error submitting feedback'});
      setDisabled(false);
    }
  };

  return(
    <>
      <Field>
        <Label className={styles.labels}>Rate your experience:</Label>
        <div className={styles.feedback}>
          {disabled ? <ThumbLikeRegular className={styles.iconButtonsDisabled} style={rating == 'positive' ? {backgroundColor: '#2B954E'} : {}}  /> : <ThumbLikeRegular className={styles.iconButtons} style={rating == 'positive' ? {backgroundColor: '#2B954E'} : {}} onClick={() => setRating('positive')} /> }
          {disabled ? <ThumbDislikeRegular className={styles.iconButtonsDisabled} style={rating == 'negative' ? {backgroundColor: '#C5261B'} : {}} /> : <ThumbDislikeRegular className={styles.iconButtons} style={rating == 'negative' ? {backgroundColor: '#C5261B'} : {}} onClick={() => setRating('negative')} />}
        </div>
      </Field>
      <Field>
        <Label className={styles.labels}>(Optional) Feedback:</Label>
        <Textarea disabled={disabled} onChange={(e) => setReview(e.target.value)} value={review} />
      </Field>
      <div className={styles.actions}>
        <Button disabled={disabled} appearance='primary' className={styles.submit} onClick={() => submitFeedback()}>Submit Feedback</Button>
        {message ? <>
          <MessageBar intent={message.intent} layout='multiline'>
            <MessageBarBody>{message.result}</MessageBarBody>
          </MessageBar>
        </> : <></>}
      </div>
    </>
  )
};