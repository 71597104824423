import React, { createContext, useState, useEffect } from 'react';
import { AuthContextType, User, login } from '../Types/AuthTypes';
import RouteProvider from '../Functions/Router';

export const AuthContext = createContext<AuthContextType | null>(null)

export const AuthWrapper = () => {
  const [ user, setUser ] = useState<User | null>(null)
  const [ authenticated, setAuthenticated ] = useState<boolean | undefined>(undefined)
  const [ error, setError ] = useState<boolean>(false)

  useEffect(() => {
    if(localStorage.getItem('token')){
      const token = localStorage.getItem('token')
      const email = localStorage.getItem('email')
      fetch(`${process.env.APISERVER}/accounts/verify`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token })
      })
      .then((res) => res.json())
      .then((data) => {
        if(!data.success){
          localStorage.removeItem('token');
          localStorage.removeItem('email');
          localStorage.removeItem('version');
          setAuthenticated(false)
        } else {
          setUser({
            upstreamEmail: email
          });
          setAuthenticated(true)
        }
      })
      .catch(() => {
        setError(true)
      })
    } else {
      setAuthenticated(false)
    }
  }, []);

  const login: login = async (email, password) => {
    try{
      const results = await fetch(`${process.env.APISERVER}/accounts/login/application`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ Email: email, Password: password})
      });

      const data = await results.json();

      if(data.success){
        if(data.newChild){
          localStorage.setItem('newChild', 'true')
        }
        localStorage.setItem('accType', data.accType);
        localStorage.setItem('token', data.token);
        localStorage.setItem('email', email);
        localStorage.setItem('splash', 'true');
        localStorage.setItem('version', `${process.env.VERSION}`)
  
        return {success: true};
      } else {
        return {success: false, message: data.message};
      }

    } catch(e) {
      console.log(e);
      return { success: false, message: e}
    }
  };

  const logout: () => void = () => {
    localStorage.removeItem('token')
    window.location.reload()
  };

  return(
    <AuthContext.Provider value={{user, error, authenticated, login, logout}}>
      <RouteProvider />
    </AuthContext.Provider>
  )
};