import React, { useContext } from 'react';
import { AuthContext } from './AuthProvider';
import { settingsStyles } from "../styles/ComponentStyles";
import { Settings32Regular } from "@fluentui/react-icons";
import {
  Button,
  Menu,
  MenuTrigger,
  MenuList,
  MenuItem,
  MenuPopover,
} from "@fluentui/react-components";

export default function Settings(){
  const auth = useContext(AuthContext);
  const styles = settingsStyles();

  return(
    <div className={styles.wrapper}>
      <Menu>
        <MenuTrigger disableButtonEnhancement>
          <Button 
            appearance="subtle" 
            size="large" 
            icon={<Settings32Regular />}
            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
            aria-label="settings"
          />
        </MenuTrigger>
        <MenuPopover>
          <MenuList>
            <MenuItem onClick={() => window.open('https://mailguard.greyforward.com/settings')}>Settings</MenuItem>
            <MenuItem onClick={() => auth.logout()}>Logout</MenuItem>
          </MenuList>
        </MenuPopover>
      </Menu>
    </div>
  )
}