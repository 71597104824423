import React from 'react';
//@ts-expect-error Why are imports so dumb
import Logo from '../assets/LogoNew.png'


import { Display, Image, makeStyles } from '@fluentui/react-components';

const headerStyle = makeStyles({
  logo: {
    paddingTop: '40px',
    display: 'Flex',
    justifyContent: 'center'
  }
})

export default function Header(){
  const styles = headerStyle();
  return(
    <>
      <div className={styles.logo}>
        <Image src={Logo} height='125px' width='auto' />
      </div>
    </>
  )
}

//Old value - 125px