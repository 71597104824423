import { makeStyles, shorthands } from '@fluentui/react-components'

export const childPassStyles = makeStyles({
  changeForm: {
    display: 'flex',
    maxWidth: '100vw',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
  },
  greetingWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
  },
  textWrapper: {
    margin: '5px',
    textAlign: 'center'
  },
  buttonWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    gap: '10px'
  },
  inputStyle: {
    marginBottom: '8px'
  },
  notificationWrapper: {
    margin: '5px'
  }
});

export const accordionStyle = makeStyles({
  wrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginBottom: '25px'
  },
  resultsText: {
    fontSize: '1em',
    fontWeight: '600',
    margin: '0'
  },
  testClass: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  }
});

export const feedbackStyles = makeStyles({
  feedback: {
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
    marginBottom: '10px'
  },
  iconButtons: {
    height: '60px',
    width: 'auto',
    border: '1px solid black',
    borderRadius: '10px',
    padding: '10px',
    cursor: 'pointer'
  },
  iconButtonsDisabled: {
    height: '60px',
    width: 'auto',
    border: '1px solid black',
    borderRadius: '10px',
    padding: '10px',
    opacity: '40%'
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  submit: {
    margin: '10px'
  },
  labels: {
    marginBottom: '8px',
    textAlign: 'center'
  }
});

export const resetStyles = makeStyles({
  passReset: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
    marginBottom: '10px',
  },
  notificationWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px'
  },
  buttons: {
    width: '250px',
    ...shorthands.margin('10px, 0px, 10px, 0px')
  },
});

export const settingsStyles = makeStyles({
  wrapper: {
    width: '100%',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    top: '-7px',
    right: '20px'
  }
})