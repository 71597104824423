import React, { useContext, lazy, Suspense } from 'react'
import { rootStyles } from './styles/PageStyles';
import { makeStyles, Spinner, MessageBar, MessageBarTitle, MessageBarBody } from '@fluentui/react-components';
import { RouteContext } from './Functions/Router';
import { AuthContext } from './components/AuthProvider';

const Landing = lazy(() => import('./Pages/Landing'));
const Login = lazy(() => import('./Pages/Login'));
const Welcome = lazy(() => import('./Pages/Welcome'));
import Analyzing from './Pages/Analyzing';
import Footer from './components/Footer';

const App: React.FC = () => {
  const route = useContext(RouteContext);
  const auth = useContext(AuthContext)
  const styles = rootStyles();

  const renderPage = () => {
    if(auth.authenticated == undefined && auth.error == false){
      return(
        <div className={styles.loading}>
          <Spinner size='large' />
          <h3>Loading...</h3>
        </div>
      )
    } else if(auth.authenticated){
      if(localStorage.getItem('splash')){
        return(<Suspense fallback={<><Spinner size='tiny' /></>}><Welcome /></Suspense>)
      };
      switch(route.page){
        case 'landing':
          return(<Suspense fallback={<><Spinner size='tiny' /></>}><Landing /></Suspense>);
        case 'analyzing':
          return(<Analyzing />);
        default:
          return(<Analyzing />);
      }
    } else if(auth.authenticated == undefined && auth.error == true){
      return(
        <>
          <MessageBar intent='error' layout='multiline' className={styles.notificationBar}>
            <MessageBarBody>
              <MessageBarTitle>Error</MessageBarTitle>
              <br />
              Unable to connect to Mail Guard services
            </MessageBarBody>
          </MessageBar>
        </>
      )
    } else {
      switch(route.page){
        case 'landing':
          return(<Suspense fallback={<><Spinner size='tiny' /></>}><Landing /></Suspense>);
        case 'login':
          return(<Suspense fallback={<><Spinner size='tiny' /></>}><Login /></Suspense>)
        default:
          return(<Suspense fallback={<><Spinner size='tiny' /></>}><Landing /></Suspense>);
      }
    }
  }

  return(
    <>
      <div className={styles.root}>
        <div className={styles.mainContent}>
          {renderPage()}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default App