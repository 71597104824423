import React, { useState, useEffect } from 'react';
import { MailSubmit, MailResponse, AnalyzeError } from '../Types/APITypes';
import { analyzeStyle } from '../styles/PageStyles';
import { Divider, Button, MessageBar, MessageBarTitle, MessageBarBody, Dialog, DialogTrigger, DialogSurface, DialogTitle, DialogBody, DialogContent } from '@fluentui/react-components';
import { ArrowClockwiseRegular, PersonFeedbackRegular } from '@fluentui/react-icons';
import { PuffLoader } from 'react-spinners';
import CaptureText from '../Functions/CaptureText';
import Feedback from '../components/Feedback';
import DetailsAccordion from '../components/DetailsAccordion';
import Header from '../components/Header';
import Settings from '../components/Settings';
import GaugeComponent from 'react-gauge-component';

export default function Analyzing(){
  const styles = analyzeStyle();
  const [ error, setError ] = useState<AnalyzeError>({show: false,  reason: ''});
  const [ results, setResults ] = useState<MailResponse | undefined>(undefined);
  const [ riskText, setRiskText ] = useState<{text: string, color: {background: string, font: string}, next: string}>();
  const [ feedback, setFeedback ] = useState<boolean>(false);

  const getRisk: (risk: string) => void = (risk) => {
    const riskNo = Number(risk.substring(0, risk.length - 1))
    if(riskNo <= 20 ){
      setRiskText({
        text: 'Low Threat Level',
        color: {
          background: '#2B954E',
          font: 'white'
        },
        next: 'This email is likely safe. Always proceed with caution and verify the sender if still unsure.',
      })
    } else if (riskNo > 20 && riskNo <= 30){
      setRiskText({
        text: 'Low Threat Level',
        color: {
          background: '#AFC657',
          font: 'black'
        },
        next: 'This email has a slight chance of being malicious. Review the content carefully and verify the sender before taking any action.'
      });
    } else if (riskNo > 30 && riskNo <= 60){
      setRiskText({
        text: 'Medium Threat Level',
        color: {
          background: '#FFD35C',
          font: 'black'
        },
        next: 'This email shows signs of potential malicious intent. Do not click any links or download attachments until you confirm the sender\'s authenticity.'
      });
    } else if (riskNo > 61 && riskNo <= 80){
      setRiskText({
        text: 'High Threat Level',
        color: {
          background: '#FF8E47',
          font: 'white'
        },
        next: 'This email is highly suspicious. Report it to your IT/security team if applicable and avoid interacting with any content within the email.'
      });
    } else {
      setRiskText({
        text: 'High Threat Level',
        color: {
          background: '#F53900',
          font: 'white'
        },
        next: 'This email is very likely to be malicious. Do not open it, delete it immediately, and notify your IT/security team if applicable.'
      })
    }
  }

  const submitReport: (data: MailSubmit) => Promise<void> = async (data) => {
    try {
      const response = await fetch(`${process.env.APISERVER}/scan/submit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(data)
      });

      const responseData = await response.json();

      if(response.ok){
        getRisk(responseData.risk)
        setResults(responseData)
      } else {
        console.log(responseData)
        setError({ show: true, reason: 'Server error while analyzing email'})
      }

    } catch(e) {
      console.log(`Error - ${e}`);
      setError({ show: true, reason: 'Error retrieving AI results'})
    }
  };

  useEffect(() => {
    const context = Office.context.mailbox

    const to = `${context.userProfile.displayName} <${context.userProfile.emailAddress}>` || undefined
    const from = `${context.item.from.displayName} <${context.item.from.emailAddress}>` || undefined
    const subject = `${context.item.subject}` || undefined

    //@TODO - Future Improvements to email gathering
    /*
      context.item.getAllInternetHeadersAsync((result) => {
        if(result.status == Office.AsyncResultStatus.Succeeded){
          console.log(result.value)
        } else {
          console.log('error')
        }
      });
    */

    context.item.body.getAsync('html', (result) => {
      if(result.status === Office.AsyncResultStatus.Succeeded){
        if(to == undefined || from == undefined || subject == undefined){
          setError({show: true, reason: "Error capturing email data"})
          return;
        }
        submitReport({ to, from, subject, body: result.value})
      } else {
        setError({show: true, reason: 'Unable to capture email body'})
      }
    })

  }, []);

  if(results == undefined){
    return(
      <>
        <Header />
        <div style={{ marginTop: '30px'}} className={styles.wrapper}>
          <PuffLoader color="#0388C2" size={200} speedMultiplier={0.7} />
          {error.show ?
            <div className={styles.notificationWrapper} style={{marginTop: '30px'}}>
              <MessageBar intent='error' layout='multiline' className={styles.notification}>
                <MessageBarBody>
                <MessageBarTitle>Error</MessageBarTitle>
                <br />
                {error.reason}
                </MessageBarBody>
              </MessageBar>
            </div> : <h2><CaptureText /></h2>
 }
        </div>
      </>
    )
  } else {
    return(
      <>
        <div className={styles.wrapper}>
          <Settings />
          <div>
            <GaugeComponent
              value={Number(results.risk.substring(0, results.risk.length - 1))}
              type="radial"
              labels={{
                valueLabel: {
                  style: {
                    display: 'none'
                  }
                },
                tickLabels: {
                  hideMinMax: true
                }
              }}
              arc={{
                colorArray: ['#2B954E', '#AFC657', '#FFD35C', '#FF8E47', '#F53900'],
                subArcs: [{}, {}, {}, {}, {}],
                padding: 0.04,
                width: 0.15
              }}
              pointer={{
                elastic: false,
                animationDelay: 35
              }}
            />
            <div className={styles.gaugeTitle}>
              <span className={styles.risk} style={{ backgroundColor: riskText.color.background, color: riskText.color.font }}>{riskText.text}</span>
              <h2 style={{marginBottom: '5px', marginTop: '5px', fontWeight: 'normal' }}>Risk:&nbsp;{results.risk}</h2>
            </div>
          </div>
          <DetailsAccordion details={results.response} />
          <div style={{width: '100%'}}>
            <Divider><h2>Next Steps</h2></Divider>
          </div>
          <div className={styles.nextSteps}>
            <p>{riskText.next}</p>
          </div>
          <div className={styles.rescan}>
            <Button size='small' appearance='outline' icon={<ArrowClockwiseRegular />} onClick={() => window.location.reload()}>Rescan</Button>
            <Dialog>
              <DialogTrigger disableButtonEnhancement>
                <Button size='small' appearance='outline' icon={<PersonFeedbackRegular />} disabled={feedback}>Feedback</Button>
              </DialogTrigger>
              <DialogSurface className={styles.feedback}>
                <DialogBody>
                  <DialogTitle><div style={{ textAlign: 'center'}}>Mailguard Feedback</div></DialogTitle>
                  <DialogContent><Feedback ID={results.ID} feedback={() => setFeedback(true)}/></DialogContent>
                </DialogBody>
              </DialogSurface>
            </Dialog>
          </div>
        </div>
      </>
    )
  }
};