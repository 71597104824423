import React, { useContext, useState } from 'react';
import { loginStyles } from '../styles/PageStyles';
import { resetStyles } from '../styles/ComponentStyles';
import { Button, Input, Label, Spinner, MessageBar, MessageBarTitle, MessageBarBody, MessageBarActions, Dialog, DialogSurface, DialogTrigger, DialogBody, DialogTitle, DialogContent } from '@fluentui/react-components';
import { DismissRegular } from '@fluentui/react-icons';
import { AuthContext } from '../components/AuthProvider';
import Header from '../components/Header';

function PasswordReset(){
  const styles = resetStyles();
  const [ email, setEmail ] = useState<string>('');
  const [ notification, setNotification ] = useState<{display: boolean, message: string, type: 'error' | 'success'}>({display: false, message: '', type: 'success'});
  const [ loading, setLoading ] = useState<boolean>(false);

  const submitReset: () => Promise<void> = async () => {
    setLoading(true)
    if(email == '') setNotification({ display: true, message: 'Please enter your email', type: 'error'})
    try {
      const request = await fetch(`${process.env.APISERVER}/accounts/pwreset`, {
        method:'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({ email })
      })

      if(!request.ok){
        const data = await request.json()
        if(data.message == 'Failed input validation'){
          setNotification({display: true, message: 'Please enter a valid email', type: 'error'});
          return;
        }
        throw new Error()
      }

      setNotification({ display: true, message: 'Password reset submitted sucessfully', type: 'success'})

    } catch(e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  return(
    <>
      <div className={styles.passReset}>
        <div>
          <p style={{margin: '0px'}}>Please enter your email address below to recieve a link to reset your password.</p>
        </div>
        <div style={{ width: '100%' }}>
          <Label htmlFor='email'>Email:</Label>
          <Input id='email' type='email' onChange={(e) => setEmail(e.target.value)} style={{width: '100%'}} />
        </div>
        <Button style={{ marginTop: '5px' }} onClick={() => submitReset()} size='large' shape='rounded' appearance='primary' disabled={loading} >{loading ? <><Spinner size={'tiny'} />&nbsp;&nbsp;Loading</> : <>Submit</>}</Button>
        {notification.display ? 
          <div className={styles.notificationWrapper}>
            <MessageBar intent={notification.type} layout='multiline' className={styles.buttons}>
              <MessageBarBody>
                <MessageBarTitle>{notification.type == 'success' ? "Success" : "Error" }</MessageBarTitle>
                <br />
                {notification.message}
              </MessageBarBody>
              <MessageBarActions containerAction={
                <Button aria-label='dismiss' appearance='transparent' icon={<DismissRegular />} onClick={() => setNotification({ display: false, message: '', type: 'success'})}/>
              } />
            </MessageBar>
          </div> : <></>
        }
      </div>
    </>
  )
}

export default function Login() {
  const styles = loginStyles();
  const auth = useContext(AuthContext);
  const [ email, setEmail ] = useState<string>('');
  const [ password, setPassword ] = useState<string>('');
  const [ loading, setLoading ] = useState<boolean>(false);
  const [ notification, setNotification ] = useState<{display: boolean, message: string, type: 'error' | 'info' | 'success'}>({display: false, message: '', type: 'info'});

  const submitLogin: () => Promise<void> = async () => {
    setLoading(true)
    try {
      const results = await auth.login(email, password)

      if(!results.success){
        setNotification({ display: true, message: results.message, type: 'error'});
        setLoading(false)
      } else {
        setNotification({ display: true, message: "Login Successful", type: 'success'})
        setTimeout(() => {
          window.location.reload();
        }, 2000)
      }
    } catch(e) {
      setLoading(false)
      console.log(e)
    }
  };

  return(
    <>
      <Header />
      <div className={styles.mainWrapper}>
        <div className={styles.loginWrapper}>
          <div className={styles.loginComponent}>
            <Label htmlFor='email'>Email:</Label>
            <Input id='email' type='email' onChange={(e) => setEmail(e.target.value)} />
          </div>
          <div className={styles.loginComponent}>
            <Label htmlFor='password'>Password:</Label>
            <Input id='password' type='password' onChange={(e) => setPassword(e.target.value)} />
            <div className={styles.resetContainer}>
            <Dialog>
              <DialogTrigger disableButtonEnhancement>
                <p className={styles.resetLink}>Forgot Password</p>
              </DialogTrigger>
              <DialogSurface className={styles.resetModalWrapper}>
                <DialogBody>
                  <DialogTitle><div style={{ textAlign: 'center'}}>Password Reset</div></DialogTitle>
                  <DialogContent>
                    <PasswordReset />
                  </DialogContent>
                </DialogBody>
              </DialogSurface>
            </Dialog>
            </div>
          </div>
        </div>
        <div className={styles.buttonWrapper}>
          <Button onClick={() => submitLogin()} size='large' className={styles.buttons} shape='rounded' appearance='primary' disabled={loading}>{loading ? <><Spinner size={'tiny'} />&nbsp;&nbsp;Loading</> : <>Submit</>}</Button>
        </div>
        {notification.display ? 
          <div className={styles.notificationWrapper}>
            <MessageBar intent={notification.type} layout='multiline' className={styles.buttons}>
              <MessageBarBody>
                <MessageBarTitle>{notification.type == 'success' ? "Success" : "Error" }</MessageBarTitle>
                <br />
                {notification.message}
              </MessageBarBody>
              <MessageBarActions containerAction={
                <Button aria-label='dismiss' appearance='transparent' icon={<DismissRegular />} onClick={() => setNotification({ display: false, message: '', type: 'info'})}/>
              } />
            </MessageBar>
          </div> : <></>
        }
      </div>
    </>
  )
}