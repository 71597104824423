import React, { createContext, useState } from 'react';
import { AvailablePages, RouteType } from '../Types/RouteTypes';
import App from '../App';

export const RouteContext = createContext<RouteType | null>(null)

export default function RouteProvider(){
  const [ page, setPage ] = useState<AvailablePages>('analyzing')

  const setLocation: (page: AvailablePages) => void = (page) => {
    setPage(page)
  };

  return(
    <RouteContext.Provider value={{page, setLocation}}>
      <App />
    </RouteContext.Provider>
  )
};