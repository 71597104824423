import React, { useState } from 'react'
import { accordionStyle } from '../styles/ComponentStyles';
import {
  Accordion, 
  AccordionHeader, 
  AccordionItem, 
  AccordionPanel
} from '@fluentui/react-components'
import { 
  ArrowMaximizeVerticalRegular, ArrowMinimizeVerticalRegular, Subtract20Filled, Add20Filled 
} from '@fluentui/react-icons'

interface DetailsProps {
  details: string
}

export default function DetailsAccordion(props: DetailsProps){
  const styles = accordionStyle();
  const [detailsOpen, setDetailsOpen] = useState<boolean>(false);

  return(
    <>
      <div className={styles.wrapper}>
        <Accordion collapsible={true} onToggle={() => setDetailsOpen(detailsOpen => !detailsOpen)}>
          <AccordionItem value='1' className={styles.testClass}>
            <AccordionHeader 
              size='extra-large'
              expandIcon={detailsOpen ? <Subtract20Filled /> : <Add20Filled />}
              
            >
              Detailed Explanation
            </AccordionHeader>
            <AccordionPanel>
              <span className={styles.resultsText} >{props.details}</span>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </>
  )
}