import { makeStyles, shorthands } from "@fluentui/react-components";

export const rootStyles = makeStyles({
  root: {
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    backgroundColor: '#ffffff',
    color: '#383838',
    minHeight: '100vh',
    height: '100%',
    paddingLeft: '10px',
    paddingRight: '10px',
    width: '100%',
    overflowX: 'hidden'
  },
  mainContent: {
    flexGrow: 1,
    paddingBottom: '10px'
  },
  loading: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '20px'
  },
  notificationBar: {
    marginTop: '45px'
  }
});

export const analyzeStyle = makeStyles({
  wrapper: {
    marginTop: '5%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%'
  },
  risk: {
    fontSize: '1.25rem',
    fontWeight: 'semibold',
    borderRadius: '20px',
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingLeft: '10px',
    paddingRight: '10px',
    marginTop: '5px',
  },
  gaugeTitle: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '-10px',
    marginBottom: '25px',
    gap: '8px'
  },
  notificationWrapper: {
    margin: '20px'
  },
  notification: {
    width: '250px',
    ...shorthands.margin('10px, 0px, 10px, 0px')
  },
  resultsGauge: {
    paddingTop: '10%'
  },
  nextSteps: {
    marginTop: '10px',
    marginBottom: '7px',
    border: '2px solid #0388C2',
    padding: '10px',
    borderRadius: '10px'
  },
  rescan: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: '10px',
    gap: '5px'
  },
  feedback: {
    marginLeft: '10px',
    marginRight: '10px'
  },
  modalTitle: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
});

export const loginStyles = makeStyles({
  buttons: {
    width: '250px',
    ...shorthands.margin('10px, 0px, 10px, 0px')
  },
  buttonWrapper: {
    marginTop: '15px',
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: '1'
  },
  notificationWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px'
  },
  loginWrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    gap: '15px',
    marginBottom: '20px',
  },
  loginComponent: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  mainWrapper: {
    marginTop: '20%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  resetContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
  },
  resetLink: {
    color: 'blue',
    fontSize: '14px',
    fontWeight: 'bold',
    cursor: 'pointer'
  },
  resetModalWrapper: {
    width: '90%',
    ...shorthands.padding('20px, 10px, 20px, 10px')
  }
});

export const landingStyles = makeStyles({
  banner: {
    marginTop: '20%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center'
  },
  greeting: {
    lineHeight: '1em',
    color: '#037EBB'
  },
  buttonRow: {
    marginTop: '30px',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-evenly'
  }
})