import React, { useState } from 'react';
import { childPassStyles } from '../styles/ComponentStyles';
import { Label, Input, Button, Spinner, MessageBar, MessageBarTitle, MessageBarBody, MessageBarActions } from '@fluentui/react-components'
import { DismissRegular } from '@fluentui/react-icons';
import Header from './Header';

export default function ChildPassword(){
  const styles = childPassStyles();
  const [ password, setPassword ] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [ loading, setLoading] = useState<boolean>(false);
  const [ notification, setNotification ] = useState<{display: boolean, message: string, type: 'error' | 'info' | 'success'}>({display: false, message: '', type: 'info'})

  const submitChange: () => Promise<void> = async () => {
    try {
      setLoading(true)
      const results = await fetch(`${process.env.APISERVER}/account-actions/updatepassword`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ password, confirmPassword, newChild: true })
      });

      const data = await results.json();
      if(!data.success){
        throw new Error (data.message)
      } else {
        setNotification({display: true, type: 'success', message: 'Password successfully updated'});
        localStorage.removeItem('newChild')
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      }
    } catch(e) {
      setLoading(false)
      setNotification({display: true, type: 'error', message: e.message})
    }
  }

  return(
    <>
      <Header />
      <div className={styles.greetingWrapper}>
        <h2>Password Change Required</h2>
      </div>
      <div className={styles.textWrapper}>
        As a new account to MailGuard, you are required to change your password to something unique before proceeding.
      </div>
      <hr />
      <div className={styles.changeForm}>
        <Label htmlFor='password'>New Password:</Label>
        <Input id='password' type='password' value={password} onChange={(e) => setPassword(e.target.value)} className={styles.inputStyle} />
        <Label htmlFor='confirmPassword'>Confirm Password:</Label>
        <Input id='confirmPassword' type='password' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} className={styles.inputStyle} />
        {notification.display ? 
          <div className={styles.notificationWrapper}>
            <MessageBar intent={notification.type} layout='multiline'>
              <MessageBarBody>
                <MessageBarTitle>{notification.type.charAt(0).toUpperCase() + notification.type.slice(1)}</MessageBarTitle>
                <br />
                {notification.message}
              </MessageBarBody>
              <MessageBarActions containerAction={
                <Button aria-label='dismiss' appearance='transparent' icon={<DismissRegular />} onClick={() => setNotification({ display: false, message: '', type: 'info'})}/>
              } />
            </MessageBar>
          </div>
        : <></>}
        <div className={styles.buttonWrapper}>
          <Button size='large' shape='rounded' appearance='primary' onClick={() => submitChange()} disabled={loading}>{loading ? <><Spinner size={'tiny'} />&nbsp;&nbsp;Loading</> : <>Submit</>}</Button>
        </div>
      </div>
    </>
  )
}